import { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { defineMessages, injectIntl } from 'react-intl';

import { Modal } from 'react-responsive-modal';
import { Chip, Divider } from '@mui/material';
import SelectUsersField from './SelectUsersField';
import SelectDashboardsField from './SelectDashboardsField';
import Button from '../../components/common/Button';

const messages = defineMessages({
  OPEN: {
    id: 'SelectUserDashboardForm.OPEN',
    defaultMessage: 'Open',
  },
  CREATE_NEW_DASHBOARD: {
    id: 'DashboardCreatePage.CREATE_NEW_DASHBOARD',
    defaultMessage: 'Create new dashboard',
  },
  CANCEL: {
    id: 'DeleteForm.CANCEL',
    defaultMessage: 'Cancel',
  },
  SELECT_USER: {
    id: 'SelectUserDashboardForm.SELECT_USER',
    defaultMessage: 'User',
  },
  NO_ITEM: {
    id: 'ResourceListPage.NO_ITEM',
    defaultMessage: 'No entries found',
  },
  OPEN_DASHBOARD: {
    id: 'SelectUserDashboardForm.OPEN_DASHBOARD',
    defaultMessage: 'Open dashboard',
  },
  COPY_DASHBOARD: {
    id: 'SelectUserDashboardForm.COPY_DASHBOARD',
    defaultMessage: 'Copy dashboard',
  },
  COPY_DASHBOARD_TO_USER: {
    id: 'SelectUserDashboardForm.COPY_DASHBOARD_TO_USER',
    defaultMessage: 'Copy dashboard to user',
  },
  LOAD_DASHBOARD: {
    id: 'SelectUserDashboardForm.LOAD_DASHBOARD',
    defaultMessage: 'Dashboard',
  },
  EITHER: {
    id: 'EITHER',
    defaultMessage: 'Either',
  },
  OR: {
    id: 'OR',
    defaultMessage: 'Or',
  },
});

class SelectUserDashboardForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user_id: null,
      user_name: null,
      dashboard_id: null,
      copy_dashboard_id: null,
    };
    this.close = this.close.bind(this);
    this.setUser = this.setUser.bind(this);
    this.setDashboardID = this.setDashboardID.bind(this);
    this.setCopyDashboardID = this.setCopyDashboardID.bind(this);
    this.clearFields = this.clearFields.bind(this);
  }

  close() {
    this.props.setShowModal(false);
  }

  setUser(user_id, user_name) {
    if (user_id === null) {
      this.props.change('copy_dashboard_id', null);
      this.props.change('dashboard_id', null);
      this.setState({
        user_id: null,
        user_name: null,
        dashboard_id: null,
        copy_dashboard_id: null,
      });
      return;
    }

    this.setState({ user_id: user_id, user_name: user_name });
  }

  setDashboardID(dashboard_id) {
    this.props.change('copy_dashboard_id', null);
    this.setState({ dashboard_id: dashboard_id, copy_dashboard_id: null });
  }

  setCopyDashboardID(dashboard_id) {
    this.props.change('dashboard_id', null);
    this.setState({ copy_dashboard_id: dashboard_id, dashboard_id: null });
  }

  clearFields() {
    this.props.change('user_id', null);
    this.props.change('dashboard_id', null);
    this.props.change('copy_dashboard_id', null);
    this.setState({ user_id: null, dashboard_id: null, copy_dashboard_id: null });
  }

  render() {
    const {
      intl: { formatMessage },
      navigate,
      showModal,
      is_select_dashboard,
      user,
      copyDashboard,
    } = this.props;

    return (
      <Modal
        open={showModal}
        onClose={this.close}
        classNames={{
          modal: 'customModal',
        }}
        center
      >
        <div>
          <form>
            <div className="tj pa1 dib v-mid h-mid w-100">
              <h3>{formatMessage(messages.LOAD_DASHBOARD)}</h3>
              <Field
                name="user_id"
                component={SelectUsersField}
                onChange={(opt) => {
                  if (opt != null) {
                    this.setUser(opt.value, opt.label);
                  } else {
                    this.setUser(null);
                  }
                }}
                config={{
                  label: formatMessage(messages.SELECT_USER),
                  label_not_found: formatMessage(messages.NO_ITEM),
                  full_value: true,
                  is_select_dashboard: is_select_dashboard,
                  // ignore_user_id: user && user.id,
                }}
              />
              <Divider className="pb3">
                <Chip label={formatMessage(messages.EITHER).toUpperCase()} size="small" />
              </Divider>
              <Field
                name="dashboard_id"
                component={SelectDashboardsField}
                onChange={(opt) => this.setDashboardID(opt)}
                config={{
                  label: formatMessage(messages.OPEN_DASHBOARD),
                  label_not_found: formatMessage(messages.NO_ITEM),
                  user_id: this.state.user_id,
                }}
              />
              <Divider className="pb3">
                <Chip label={formatMessage(messages.OR).toUpperCase()} size="small" />
              </Divider>
              <Field
                name="copy_dashboard_id"
                component={SelectDashboardsField}
                onChange={(opt) => this.setCopyDashboardID(opt)}
                config={{
                  label: formatMessage(messages.COPY_DASHBOARD_TO_USER),
                  label_not_found: formatMessage(messages.NO_ITEM),
                  user_id: user.id,
                }}
              />
              <div className="db tl">
                <Button type="button" onClick={this.close} label={formatMessage(messages.CANCEL)} />
                {this.state.dashboard_id && (
                  <Button
                    activestyle="primary"
                    type="submit"
                    disabled={!this.state.dashboard_id}
                    label={formatMessage(messages.OPEN)}
                    onClick={(e) => {
                      e.preventDefault();
                      if (this.state.dashboard_id) {
                        navigate(`/dashboard/${this.state.dashboard_id}/`, {
                          state: {
                            external_user_id: this.state.user_id,
                            external_user_name: this.state.user_name,
                          },
                        });
                      }
                    }}
                    marginLeft
                  />
                )}
                {!this.state.dashboard_id &&
                  !this.state.copy_dashboard_id &&
                  this.state.user_id && (
                    <Button
                      activestyle="success"
                      type="submit"
                      disabled={!this.state.user_id}
                      label={formatMessage(messages.CREATE_NEW_DASHBOARD)}
                      onClick={(e) => {
                        e.preventDefault();
                        if (this.state.user_id) {
                          navigate(`/dashboard/create/`, {
                            state: {
                              external_user_id: this.state.user_id,
                              external_user_name: this.state.user_name,
                            },
                          });
                        }
                      }}
                      marginLeft
                    />
                  )}
                {this.state.copy_dashboard_id && this.state.user_id && (
                  <Button
                    activestyle="success"
                    type="submit"
                    disabled={!this.state.user_id}
                    label={formatMessage(messages.COPY_DASHBOARD)}
                    onClick={(e) => {
                      e.preventDefault();
                      if (this.state.user_id && this.state.copy_dashboard_id) {
                        const t = copyDashboard({
                          user_id: this.state.user_id,
                          copy_dashboard_id: this.state.copy_dashboard_id,
                          self_user_id: user.id,
                          dashboard_order: user.dashboard_order || [],
                        });
                        if (t) {
                          this.clearFields();
                        }
                        if (t && this.state.user_id === user.id) {
                          // workaround to refresh dashboard list
                          this.props.sidebarToggle();
                        }
                      }
                    }}
                    marginLeft
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(reduxForm({ form: 'selectUserDashboard' })(SelectUserDashboardForm));
