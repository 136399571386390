import { defineMessages, injectIntl } from 'react-intl';
import { MoreVert } from '@mui/icons-material';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../components/common/Button';
// import request from '../../../api/request';
// import convertDashboardToTemplate from '../../../utils/convertDashboardToTemplate';

const messages = defineMessages({
  EDIT_DASHBOARD: {
    id: 'DashboardUpdatePage.EDIT_DASHBOARD',
    defaultMessage: 'Edit dashboard',
  },
  LABEL_ADD_WIDGET: {
    id: 'DashboardSinglePage.LABEL_ADD_WIDGET',
    defaultMessage: 'Add widget',
  },
  LABEL_DELETE_DASHBOARD: {
    id: 'DashboardSinglePage.LABEL_DELETE_DASHBOARD',
    defaultMessage: 'Delete dashboard',
  },
  LABEL_DONE: {
    id: 'DashboardSinglePage.LABEL_DONE',
    defaultMessage: 'Done',
  },
  LABEL_EDIT: {
    id: 'DashboardSinglePage.LABEL_EDIT',
    defaultMessage: 'Edit',
  },
  DOWNLOAD_TEMPLATE: {
    id: 'DashboardSinglePage.DOWNLOAD_TEMPLATE',
    defaultMessage:
      'Download this dashboard as a template. You can use this while creating a dashboard.',
  },
  SYNC_WITH_TEMPLATE: {
    id: 'DashboardSinglePage.SYNC_WITH_TEMPLATE',
    defaultMessage: 'advanced feature: sync with template',
  },
});

const StyledButton = styled(Button)`
  @media (max-width: 1125px) {
    & > i {
      margin: 0;
    }
    & > span {
      display: none;
    }
  }
`;
const ButtonDone = styled(StyledButton)``;
const ButtonChange = styled(StyledButton)``;
const ButtonAdd = styled(StyledButton)``;

const MoreButton = styled.button`
  color: ${({ theme }) => theme.color};
  border-radius: 0.2rem;
  border: none;
  padding: 0.2rem;
  margin-left: 0.5rem;
  background: ${({ theme }) => theme.button.default.backgroundColor};
  &:hover {
    background: ${({ theme }) => theme.button.default.backgroundHover};
  }
`;

// const SyncButton = styled.button`
//   color: ${({ theme }) => theme.color};
//   border-radius: 0.2rem;
//   border: none;
//   padding: 0.2rem;
//   margin-right: 0.5rem;
//   background: ${({ theme }) => theme.button.default.backgroundColor};
//   &:hover {
//     background: ${({ theme }) => theme.button.default.backgroundHover};
//   }
// `;

const DashboardControls = styled.div`
  color: ${({ theme }) => theme.color};
`;
const ContainerMore = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  border-radius: 0.2rem;
  background: ${({ theme }) => theme.button.default.backgroundColor};
  box-shadow: ${({ theme }) => theme.header.boxShadow};
`;

function DashboardUpdateNavigationBar(props) {
  const {
    location,
    showMoreOptions,
    showMore,
    currentDashboard,
    windowWidth,
    setUpdateMode,
    intl: { formatMessage },
    show_edit,
  } = props;

  if (show_edit === false) {
    window.location.assign(`/`);
    return null;
  }

  return (
    <DashboardControls className="flex-auto flex pv2">
      <div className="flex-row flex align-center" style={{ position: 'relative' }}>
        <ButtonDone
          to={{
            pathname: `/dashboard/${currentDashboard.id}/`,
            search: location.search,
            state: { is_edit: true },
          }}
          activestyle="success"
          title={formatMessage(messages.LABEL_DONE)}
          icon="fa fa-check"
          onClick={() => setUpdateMode(false)}
          marginLeft
        />
        <ButtonAdd
          to={{
            pathname: `/dashboard/${currentDashboard.id}/widget/create_widget/`,
          }}
          title={formatMessage(messages.LABEL_ADD_WIDGET)}
          icon="fa fa-plus"
          activestyle="primary"
          marginLeft
        />
        {windowWidth >= 749 && (
          <ButtonChange
            to={{ pathname: `/dashboard/${currentDashboard.id}/update/` }}
            title={formatMessage(messages.EDIT_DASHBOARD)}
            icon="fas fa-cog"
            marginLeft
          />
        )}
        {windowWidth >= 749 && (
          <>
            <Button
              component={({ children, ...rest }) => (
                <a title={formatMessage(messages.DOWNLOAD_TEMPLATE)} {...rest}>
                  {children}
                </a>
              )}
              href={`data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(currentDashboard, null, 2)
              )}`}
              title={formatMessage(messages.DOWNLOAD_TEMPLATE)}
              data={currentDashboard}
              download={`dashboardconfig-${currentDashboard.id}-${currentDashboard.name}.json`}
              icon="fa fa-download"
              flat
              marginLeft
            />
          </>
        )}

        {/* Mobile View */}
        {windowWidth <= 749 && (
          <div style={{ position: 'relative' }}>
            <MoreButton onClick={showMoreOptions} type="button">
              <MoreVert />
            </MoreButton>
            {showMore && (
              <ContainerMore>
                <Button
                  to={{ pathname: `/dashboard/${currentDashboard.id}/update/` }}
                  title={formatMessage(messages.EDIT_DASHBOARD)}
                  icon="fa fa-pencil-alt"
                />
                <Button
                  component={({ children, ...rest }) => (
                    <a title={formatMessage(messages.DOWNLOAD_TEMPLATE)} {...rest}>
                      {children}
                    </a>
                  )}
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(currentDashboard, null, 2)
                  )}`}
                  title={formatMessage(messages.DOWNLOAD_TEMPLATE)}
                  data={currentDashboard}
                  download={`dashboardconfig-${currentDashboard.id}-${currentDashboard.name}.json`}
                  icon="fa fa-download"
                  flat
                  marginTop
                />
                {/* <Button
                  component={({ children, ...rest }) => (
                    <a title={formatMessage(messages.DOWNLOAD_TEMPLATE)} {...rest}>
                      {children}
                    </a>
                  )}
                  data={currentDashboard}
                  download={`dashboardtemplate-${currentDashboard.id}-${
                    currentDashboard.name
                    }.json`}
                  convert
                  icon="fa fa-download"
                  flat
                /> */}
              </ContainerMore>
            )}
          </div>
        )}
      </div>
    </DashboardControls>
  );
}

export default connect((state) => ({}))(injectIntl(DashboardUpdateNavigationBar));
